import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { transformPrice } from '../../../utils/formattes';
import GameTimer from '../../../components/GameTimer';
import LotteriesContext from '../../../state/LotteriesContext';
import pencil from '../../../assets/images/pencil.png';
import './checkout.scss';
import find from 'lodash/find';
import { injectIntl } from 'gatsby-plugin-intl';
import SignUpForm from '../../../components/pages/Free-tickets/SignUpForm';

const Checkout = ({ intl: { formatMessage } }) => {
  const { lotteries } = useContext(LotteriesContext);
  const powerBall = lotteries?.find((element) => element.id === 1);
  const [tickets, setTickets] = useState([]);
  const translate = (str) => (formatMessage({ id: str }));

  const handleBack = () => {
    navigate('/free-tickets');
  };

  useEffect(() => {
    const newTickets = localStorage.getItem('free-tickets');
    if (newTickets) {
      setTickets(JSON.parse(newTickets));
    }
    if (!newTickets) {
      handleBack();
    }
  }, []);

  return (
    <div className="free-tickets-wrapper checkout-wrapper-page">
      <div className="wrapper">
        <Helmet>
          <title>
            Lotteroo |
            {' '}
            {translate('Checkout - Step 2')}
          </title>
        </Helmet>
        <div className="content">
          <div className="game-block">
            <div className="left">
              <div className="img">
                <img src={powerBall?.logoImgUrl} alt="" width={100} />
              </div>
              <div className="info">
                <div className="text">{translate('Next Jackpot')}</div>
                <div className="stake">{transformPrice(powerBall?.jackpot, 'amountWithSpaces')}</div>
              </div>
            </div>
            <div className="right timer">
              <GameTimer time={powerBall?.endBuingTime} className="bright" />
            </div>
          </div>
          <div className="page-content">
            <div className="checkout-title">
              {translate('Checkout')}
            </div>
            <div className="tickets">
              <ul>
                <li className="ttl">
                  <div className="game-ttl">{translate('Game')}</div>
                  <div className="selected-ttl">{translate('Selected numbers')}</div>
                </li>
                {tickets.map((element, i) => {
                  const { pricePerLine }: any = find(element.prices, { quantity: 1 });
                  return (
                    <li>
                  <div className="game-logo">
                      <img src={element.game.logoImgUrl} alt="" />
                    </div>
                  <div className="selected">
                      {element.mainNumbers.join(', ')}
                      ,
                      {element.bonusNumbers.join(', ')}
                    </div>
                  <div className="price">
                      {i === 0 ? <span className="">Free</span> : transformPrice(pricePerLine / 2, 'forCart')}
                    </div>
                  <button className="edit" onClick={handleBack}>
                      <img src={pencil} alt="" />
                    </button>
                </li>
                  );
                })}
              </ul>
            </div>

            <SignUpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Checkout);
