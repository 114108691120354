import React, { useContext, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import {injectIntl} from "gatsby-plugin-intl"
import trust from '../../../assets/images/trust.png';
import defaultAvatar from '../../../assets/icons/default-avatar-vector.svg';
import ApplicationContext from '../../../state/ApplicationContext';
import Input from '../../Forms/Input';
import Select from '../../Forms/Select';
import 'react-datepicker/dist/react-datepicker.css';
import './sign-up.scss';
import { checkCountry, getConfig, userSignUp } from "../../../api"
import {
  emailValidate, firstNameValidate, lastNameValidate, notEmpty, passwordValidate
} from "../../../utils/validation"
import { subYears } from 'date-fns';

const SignUpForm = ({intl: { formatMessage }}) => {
  const {
    setShowSignIn, user, setUser, setShowSignUp
  } = useContext(ApplicationContext);
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      setCurrentUser(JSON.parse(userData));
    }
    if(!user){
      setCurrentUser(null)
    }
  }, [user]);

  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryLoaded, setCountryLoaded] = useState(false);
  const [startDate, setStartDate] = useState('');

  const options = countries.map((element) => ({
    label: element.name,
    value: element.shortName,
  }));

  useEffect(() => {
    checkCountry().then((response) => {
      setCountry(response.data.country);
      setCountryLoaded(true);
    });
    getConfig().then((response) => {
      setCountries(response.data.countries);
    });
  }, []);

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    date: '',
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateField = (fieldName: string):void => {
    switch (fieldName) {
      case 'email':
        const emailValidation = emailValidate(email);
        if (!emailValidation && !errors.email.length) {
          setErrors({
            ...errors,
            email: formatMessage({ id: 'Please enter you email address.' }),
          });
        }
        if (emailValidation && errors.email.length) {
          setErrors({
            ...errors,
            email: '',
          });
        }
        break;
      case 'password':
        if (!passwordValidate(password) && !errors.password.length) {
          setErrors({
            ...errors,
            password: formatMessage({ id: 'Please enter your password.' }),
          });
        }
        if (passwordValidate(password) && errors.password.length) {
          setErrors({
            ...errors,
            password: '',
          });
        }
        break;
      case 'firstName':
        if (!firstNameValidate(firstName) && !errors.firstName.length) {
          setErrors({
            ...errors,
            firstName: formatMessage({ id: 'Please enter your given name.' }),
          });
        }
        if (firstNameValidate(firstName) && errors.firstName.length) {
          setErrors({
            ...errors,
            firstName: '',
          });
        }
        break;
      case 'lastName':
        if (!lastNameValidate(lastName) && !errors.lastName.length) {
          setErrors({
            ...errors,
            lastName: formatMessage({ id: 'Please enter your surname.' }),
          });
        }
        if (lastNameValidate(lastName) && errors.lastName.length) {
          setErrors({
            ...errors,
            lastName: '',
          });
        }
        break;
      case 'date':
        if(!startDate){
          setErrors({
            ...errors,
            date: formatMessage({ id: 'Please enter your date of birth' }),
          });
        }
      default:
        break;
    }
  };

  const handleCountryChange = (value) => setCountry({
    ...country,
    shortName: value.value,
    name: value.label,
  });

  const handleChange = (date) => {
    setStartDate(date);
  };

  const isInvalid = () => {
    const errorsValues = Object.values(errors);
    return notEmpty(errorsValues)
  }

  const isEmpty = () => {
    const allValues = [email, password, firstName, lastName, startDate];
    console.log(allValues)
    return allValues.every((element) =>  element && String(element).trim().length > 0)
  }

  const handleSubmit = (event: Event) => {
    event.preventDefault();
    validateField('email');
    validateField('password');
    validateField('firstName');
    validateField('lastName');
    validateField('date');
    if(!isInvalid() || !isEmpty()) return false;

    setIsLoading(true);
    userSignUp({
      country: country.shortName,
      dob: startDate,
      email: email,
      language: "en",
      lname: lastName,
      name: firstName,
      notifications: 0,
      password: password,
      status: 10,
    }).then((response) => {
      localStorage.setItem('userToken', response.data.authKey)
      localStorage.setItem('userData', JSON.stringify(response.data))
      ApplicationState.setUser(response.data)
      setShowSignUp(false);
    }).catch((error) => {
      const errorCountry = error.response.data.errors.country[0];
      handleGrowl(true, errorCountry)
    }).finally(() => {
      setIsLoading(false);
    })
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setUser(null);
    localStorage.removeItem('userData');
    localStorage.removeItem('userToken');
  }
  const CustomDate = ({
    value, onClick, onChange, placeholder,
  }) => (
    <Input
      labelName={formatMessage({id: 'Date of birth*'})}
      value={value}
      onClick={onClick}
      onChange={() => {}}
      placeholder={placeholder}
      name="date"
      error={''}
      validateField={validateField}
      date
      autocomplete="off"
    />
  );

  return (
    <div className="signup-tickets-wrapper">
      <form noValidate onSubmit={handleSubmit}>
        {!currentUser ? (
          <div className="signup form">
            <div className="title"> {formatMessage({id: 'Log in to account'})}</div>
            <div className={'non-login-container'}>
              <div className={'sign-up-button-tickets ticket-sign-up-button'}>
                    <button onClick={() => setShowSignUp(true)}>
                      {formatMessage({id: 'Sign Up'})}
                    </button>
              </div>
              <div className={'sign-in-button-tickets ticket-sign-up-button'}>
                    <button onClick={() => setShowSignIn(true)}>
                      {formatMessage({id: 'Sign In'})}
                    </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="logged-in">
              <div className="title">
                {formatMessage({id: 'Your Details'})}
              </div>
              <div className="box">
                <div className="ttl">{formatMessage({id: 'You are logged in as:'})}</div>
                <div className="user">
                  <div className="img">
                    <img src={user?.avatar ? `${user?.avatar}_40x40.jpg` : defaultAvatar} alt=""/>
                  </div>
                  <div className="info">
                    <div className="name">{user?.name} {user?.lname}</div>
                    <div className="logout" ><a href={'/logout'} onClick={handleLogout}>{formatMessage({id: 'Logout'})}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
      <div className="certificates">
        <img src={trust} alt="" />
      </div>


    </div>
  );
};

export default injectIntl(SignUpForm);
