
export const emailValidate = (email) => {
  const EMAIL_PATTER = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
  return EMAIL_PATTER.test(email.trim());
};

export const passwordValidate = (password) => password.trim().length > 5;

export const firstNameValidate = (name) => name.trim().length > 1;

export const lastNameValidate = (name) => name.trim().length > 1;

export const notEmpty = (notEmptyArray):boolean => notEmptyArray.every((element) => Boolean(element.trim().length === 0));
