import React, { memo } from 'react';
import './input.scss';
import classNames from 'classnames';
import {injectIntl} from "gatsby-plugin-intl"

const Input = ({
  labelName, value, onBlur, name, maxValue, type, error, required, errorMessage, forgotPassword, disabled, errorNum, onChange, onClickForgot, validateField, disableError, date, onlyNumbers, maxLength, isNum, intl: { formatMessage }, ...props
}) => {
  const handleChange = (event) => {
    if (date) {
      onChange(event);
      return;
    }
    if (onlyNumbers) {
      onChange(event.target.value.replace(/\D/g, ''));
    } else {
      onChange(event.target.value);
    }
    if (error.length) {
      validateField(name);
    }
  };
  const handleBlur = (event) => {
    if(onBlur){
        onBlur();
    }
    if (!date) {
      if (onlyNumbers) {
        const formattedValue = event.target.value.replace(/\D/g, '');
        if(maxValue){
          onChange(Number(formattedValue) > maxValue ? String(maxValue): formattedValue)
        } else {
          onChange(formattedValue)
        }
      } else {
        onChange(event.target.value.trim());
      }
    }
    validateField(name);
  };
  return (
    <fieldset>
      <label className="label">
        {forgotPassword ? <a onClick={onClickForgot}>{formatMessage({
          id: 'Forgot Password?'
        })}</a> : null}
        {' '}
        {labelName}
      </label>
      <input
        value={value}
        name={name}
        type={type}
        onBlur={handleBlur}
        autocomplete="off"
        onChange={handleChange}
        maxLength={maxLength}
        className={classNames({ 'input-validate-error': error?.trim().length, disabled })}
        {...props}
        required={required}
      />
      {error?.trim().length && !disableError || errorNum ? (
        <div className={"validate-error"} dangerouslySetInnerHTML={{ __html: !errorNum ? error :  `${error ? `${error} <br />` : ''} ${errorNum}` }}>
        </div>
) : null}
    </fieldset>
  );
};

export default memo(injectIntl(Input));
